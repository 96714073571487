<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { scrollToTop } from '@/js/use/scrollUtils.js';

const isVisible = ref(false);
const isLocked = ref(false);

const handleScroll = () => {
  isVisible.value = window.scrollY > 100;
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<template>
  <button v-show="isVisible" @click="() => scrollToTop(2500)" class="scroll-to-top" :disabled="isLocked">
    <div class="top-arrow-bloc">
      <div></div>
      <div></div>
    </div>
  </button>
</template>

<style lang="scss" scoped>
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 40px;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(196, 85, 89, 0.50);
  padding: 10px 7.365px 9.885px 7px;
  background: rgba(238, 232, 224, 0.538);
  border-radius: 50%;
  transition: all 0.3s ease;
  z-index: 999;
  &:hover,
  &:focus {
    background: rgba(238, 232, 224, 0.80);
    border: 3px solid rgba(196, 85, 89, 0.50);
    cursor: pointer;
    transform: scale(1.1);
    transition: all 0.3s ease;
  }

  &:active {
    background: rgb(238, 232, 224);
    border: 1px solid #ff4248;
  }

  &:disabled {
    background: rgba(238, 232, 224, 0.538);
    border: 1px solid rgba(196, 85, 89, 0.50);
    cursor: not-allowed;
  }

  .top-arrow-bloc {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 35.511px;
      height: 0px;
      background-color: $red-color;
      border-top: 2px solid $red-color; /* Crée une ligne horizontale */
      margin-right: -10px;
    }

    div:nth-child(1) {
      transform: rotate(-58deg);
    }

    div:nth-child(2) {
      transform: rotate(58deg);
      margin-right: 0;
    }
  }
}
</style>