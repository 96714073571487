<template>
    <main>
        <HeaderAbout itemprop="header"></HeaderAbout>
        <!-- doula-quote section -->
        <DoulaQuote itemprop="mainEntity"></DoulaQuote>

        <!-- doula-story section -->
        <DoulaStory itemprop="mainEntity"></DoulaStory>

        <!-- doula-about section -->
        <DoulaAbout itemprop="mainEntity"></DoulaAbout>

        <!-- association section -->
        <Association itemprop="mainEntity"></Association>

    </main>
    
</template>

<script setup>
import { ref } from 'vue';
import { Association, DoulaAbout, DoulaStory, DoulaQuote, HeaderAbout } from '@/js/import/importView.js';


const message = ref('Hello World!');
</script>

<style scoped>

</style>