<template>
    <div class="error-page container">
        <div class="error-page-content container">
            <h1 class="text-red">404 - Page non trouvée</h1>
            <p>OUPS, La page que vous recherchez n'existe pas.</p>
            <p>Vous pouvez retourner à l'accueil en cliquant sur le bouton ci-dessous.</p>
            
            <div class="btn-block">
                <Button 
                        :colorClass="'bg-red text-light'" 
                        buttonClass="button" 
                        :hoverColorClass="'hover-bg-nav hover-text-link'" 
                        :isScrollButton="true"
                        buttonText="Retour à l'accueil"
                        sizeClass="size-medium"
                        paddingClass="padding-medium"
                        marginClass="margin-medium"
                        to="/"
                      />
            </div>

            
        </div>

    </div>
  </template>
  
  <script setup>
  import { useRoute } from 'vue-router';
  import { Button } from '@/js/import/importComponents.js';

  const route = useRoute();
  </script>
  
  <style lang="scss" scoped>
  .error-page {
    text-align: center;
    min-height: 100svh;
    place-content: center;
    @extend .bg-section-1;

  }

  .error-page-content {
    @include flex-center;
    @include flex-column;
    gap: rem(20);
    place-items: center;
    @extend .bg-section-4;
  }
  
  .error-page h1 {
    @include font-size-responsive(3rem, 2rem);
  }
  
  .error-page p {
    font-size: 1.5rem;
    margin: 20px 0;
  }
  </style>