<script setup>
import { ref, useHead } from '@/js/import/importHead.js';

const title = ref("Qu'est-ce qu'une doula ?");
</script>

<template>
      <!-- doula-quote section -->
      <section id="doula-quote" class="box-shadow-1" itemscope itemtype="https://schema.org/WebPageElement">
        <blockquote cite="https://doulas.info/une-doula-cest-quoi/" class="citation container-text" itemprop="mainEntity" itemscope itemtype="https://schema.org/Quote">
          <h2 class="doula-quote-title" itemprop="about">{{ title }}</h2>
          <p class="citation-paragraphe" itemprop="text">Une doula est une personne aux cotés de la femme enceinte et de celles et ceux qui l'entourent,
            elle est à l'écoute de ses désirs, ses besoins et la suit dans ses choix.
            Elle propose une présence dans la continuité, une relation de confiance, 
            de complicité et d'intimité. Elle écoute, accompagne, soutient,
            entoure, les femmes seules ou les couples, et leurs proches.</p>
          <footer>
            <cite itemprop="author"><a href="https://doulas.info/une-doula-cest-quoi/" target="_blank" class="a-link-none">Doulas de France</a></cite>
          </footer>
        </blockquote>
      </section>
</template>

<style lang="scss" scoped>

 /* blockquote */

 #doula-quote {
    background-color: $bg-section-4;
    @include flex-column;
    padding: rem(32) 0;
    
  }

  .doula-quote-title {
    text-align: center;
    @include font-size-responsive(rem(24), rem(32));
  }

  .citation {
      gap: rem(16);
      @include flex-column;
      text-align: center;
      font-family: $font-monkey;
      padding: $padding-size;
      color: $purple;
      @include font-size-responsive(rem(14), rem(18));
      line-height: 1.3;
  }
        .citation-paragraphe {
          background-color: #ffffff38;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: rem(20) 0;
          padding: rem(60);
          max-height: rem(330);
          @include font-size-responsive(rem(14), rem(20));
          line-height: 1.8;
          font-style: italic;
          margin-bottom: rem(20);
            quotes: "“" "”" "‘" "’";
            position: relative;
        }

        .citation-paragraphe::before {
            content: open-quote;
            @include font-size-responsive(rem(70), rem(120));
            margin-right: rem(10);
            position: absolute; 
            top: rem(-45);
            left: rem(10);
        }
        
        .citation-author {
            font-size: 1.2rem;
            font-weight: bold;
        }




</style>
 