<template>
  <div class="map-container">
    <iframe style="border:0; width:300px; height:300px;"
      src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d6399.82578322047!2d3.357411040964068!3d49.75301049148394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x47e83da88d0803a9%3A0x5ef25c6c0520bb71!2s14%20Cit%C3%A9%20Gambetta%2C%2002610%20Mo%C3%BF-de-l&#39;Aisne!3m2!1d49.7550914!2d3.3614165999999996!5e0!3m2!1sfr!2sfr!4v1731406432988!5m2!1sfr!2sfr"
      class="google-map"
      allowfullscreen="true"
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade">
    </iframe>
  </div>
</template>