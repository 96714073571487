<script setup>
import { ref, useHead } from '@/js/import/importHead.js';
import { GroupButton } from '@/js/import/importComponents.js';

const title = ref('Bain rituel');

useHead({
  title: title.value,
  meta: [
    { name: 'description', content: 'Découvrez le bain rituel d\'accueil pour bébé avec NatHalité Doula. Une expérience intime pour renforcer le lien avec votre bébé.' },
    { name: 'keywords', content: 'bain rituel, accueil bébé, NatHalité Doula, lien parent-bébé, bien-être' },
  ],
});

function updateTitle(newTitle) {
  title.value = newTitle;
}

</script>

<template>
  <article class="service-content parallax" itemscope itemtype="https://schema.org/Article" itemprop="articleBody">

    <div class="background-scroll-container">
        <img src="@/assets/service-img/bain-rituel.jpg" itemprop="image" alt="Image bain rituel" style="display: none;" /> <!-- Image pour SEO -->
    </div>

    <!-- Header -->
    <header class="container box-shadow-1 header header-parallax-bg text-dark" itemprop="headline">
      <h1 itemprop="name">{{ title }}</h1>
      <p class="service-content__text_bold">
        Je vais vous faire une présentation du <strong itemprop="name">bain rituel</strong> d'accueil pour bébé,
        où vous et votre <strong itemprop="name">bébé</strong> serez tous les deux dans la <strong itemprop="name">baignoire</strong>. 
        Ce type de <strong itemprop="name">bain</strong> est une expérience intime et apaisante qui <strong itemprop="name">renforce le 
        lien</strong> entre vous et votre <strong itemprop="name">bébé</strong>.
      </p>
    </header>

    <!-- Section de préparation -->

    <div class="container ">
      <div class="logo">
        <img class="img-logo" src="@/assets/logo_nathalite_doula.png" alt="Logo de NatHalité Doula" itemprop="image"/>
      </div>
    </div>

      <div class="container container-text">

      <div class="service-content__block-bg">
        <section class="container" itemprop="articleSection">
        
          <div class="service-content__block">
            <h2>Préparation du bain rituel d'accueil</h2>
            <p class="service-content__text_bold">
              Si vous désirez partager le récit de votre <strong>accouchement</strong> avec moi, ensuite, 
              vous mettrez une intention et un but à ce <strong>bain</strong>.
            </p>    
          </div>

          <div class="service-content__block">
            <ol class="list">
              <li><strong>Ambiance</strong>
                <ul class="list">
                  <li><strong>Lieu calme :</strong> Assurez-vous que l'endroit est tranquille et sans perturbations.</li>
                  <li><strong>Lumière tamisée :</strong> Créez une atmosphère douce avec une lumière tamisée.</li>
                  <li><strong>Musique douce :</strong> Une musique apaisante peut aider à créer un environnement relaxant.</li>
                </ul>
              </li>
              <li><strong>Température de l'eau</strong>
                <ul class="list">
                  <li><strong>Eau tiède :</strong> La température de l'eau doit être entre 36 et 37°C.</li>
                </ul>
              </li>
              <li><strong>Matériel nécessaire</strong>
                <ul>
                  <li><strong>Une baignoire</strong> suffisamment grande pour vous deux.</li>
                  <li><strong>Un thermomètre</strong> pour vérifier la température de l'eau.</li>
                  <li><strong>Des serviettes</strong> douces et chaudes.</li>
                  <li><strong>Des produits</strong> de bain adaptés aux bébés (savon doux et shampoing hypoallergénique).</li>
                </ul>
              </li>
            </ol>
          </div>
        </section>

    <!-- Section de déroulement -->
    <section class="container" itemprop="articleSection">
      <div class="service-content__block">
        <h2>Déroulement du bain rituel d'accueil</h2>
      </div>

      <div class="service-content__block">
        <ol class="list">
          <li><strong>Préparation</strong></li>
          <ul class="list">
            <li>
              <strong>Votre préparation :</strong> Avant de commencer, assurez-vous que vous êtes propre. Prenez une douche rapide
              pour enlever toute saleté ou huile corporelle.
            </li>
            <li>
              <strong>Préparation du bébé :</strong> Déshabillez doucement votre bébé en lui parlant calmement et en le rassurant.
            </li>
          </ul>
          <li><strong>Entrez dans l'eau</strong></li>
          <ul class="list">
            <li>
              <strong>Vous d'abord :</strong> Entrez d'abord dans la baignoire et asseyez-vous confortablement.
            </li>
            <li>
              <strong>Votre bébé ensuite :</strong> Faites-vous aider par votre partenaire ou votre doula pour vous passer le bébé. 
              Tenez-le fermement en soutenant sa tête et son cou.
            </li>
          </ul>
          <li><strong>Bain ensemble</strong></li>
          <ul class="list">
            <li>
              <strong>Contact peau à peau :</strong> Maintenez un contact peau à peau en posant le bébé contre votre poitrine.
              Cela aide à réguler sa température et à le rassurer.
            </li>
            <li><strong>Douceur et lenteur :</strong> Utilisez vos mains pour doucement asperger de l'eau sur le bébé. Lavez-le
              doucement en utilisant des mouvements lents et apaisants.
            </li>
          </ul>
          <li><strong>Interaction</strong></li>
          <ul class="list">
            <li>
              <strong>Parlez et chantez :</strong> Parlez doucement ou chantez à votre bébé pendant le bain. 
              Cela renforce votre lien et crée une atmosphère sereine.
            </li>
            <li><strong>Regardez dans les yeux :</strong> Maintenez un contact visuel fréquent avec votre bébé pour le rassurer.</li>
          </ul>
          <li><strong>Sortie du bain :</strong></li>
          <ul class="list">
            <li>
              <strong>Transfert sécurisé :</strong> Faites-vous aider pour sortir le bébé de l'eau.
              Enveloppez-le immédiatement dans une serviette chaude.
            </li>
            <li><strong>Vous sortez ensuite :</strong> Sortez ensuite de la baignoire en veillant à ne pas glisser.</li>
          </ul>
          <li><strong>Après bain</strong></li>
          <ul class="list">
            <li>
              <strong>Séchage et habillage du bébé :</strong> Séchez le bébé avec des tapotements doux et habillez-le 
              avec des vêtements confortables.
            </li>
            <li><strong>Temps de câlin :</strong> Prenez un moment pour câliner et réconforter votre bébé après le bain.</li>  
          </ul>
        </ol>
      </div>

      <div class="service-content__block">
        <h3>Conseils supplémentaires</h3>
      </div>

      <section class="service-content__block" itemprop="articleSection">
        <ul class="list">
          <li>
            <strong>Fréquence :</strong> Un bain rituel de ce type peut être fait une fois par semaine ou selon 
            votre convenance et le confort de votre bébé.
          </li>
          <li><strong>Sécurité avant tout :</strong> Assurez-vous que la baignoire est sécurisée et que vous avez
            tout ce dont vous avez besoin à portée de main.
          </li>  
          <li><strong>Produits naturels :</strong> Utilisez des produits de bain naturels et sans parfum pour éviter
            les irritations cutanées.
          </li>
        </ul>

        <div class="service-content__block">
            <p>
              Le <strong>bain rituel</strong> partagé est une <strong>expérience profondément enrichissante</strong> pour vous et votre bébé. Il favorise une connexion émotionnelle forte et offre un moment de détente et de douceur pour vous deux. il y aura des surprise avant et après le bain.
              je vous expliquerai lors d'un entretien téléphonique.
            </p>
        </div>
        </section>
      </section>  

         <!-- Footer -->
    

    </div>

    <div class="container">

        <div class="service-end">

          <p>Pour plus d'information, n'hésitez pas à nous contacter</p>

          <div class="row justify-center">
            <GroupButton />
            </div>
        </div>
      </div>
    </div>

  </article>
</template>
<script>


export default {
name: 'Bain rituel',
}

</script>

<style lang="scss" scoped>
@import '../../styles/services_item.scss';

.parallax { 
  background-image: url('@/assets/service-img/bain-rituel.jpg');
}

* :not(h1, h2, h3, h4, h5, h6){
  @include font-size-responsive(0.8rem, 1rem);
}

.service-content__block-bg {
  background-color: hsla(0, 0%, 82%, .549);
  max-width: max-content;
  padding: rem(16);
  @extend .text-dark;
}

.service-end {
  padding: rem(32);
  @include font-size-responsive(rem(16), rem(24));
  gap: rem(32);
}

</style>

