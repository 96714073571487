<template>
  <section id="contact" class="container box-shadow-1" itemscope itemtype="https://schema.org/ContactPage">
    <header>
      <div class="logo">
        <img class="img-logo" :src="$logo" alt="Logo de NatHalité Doula" itemprop="image" />
      </div>
      <h2 itemprop="name">Me contacter</h2>
    </header>

    <div class="contact-content">
      <ContactInfo />
      <ContactForm @submit="handleFormSubmit" />
    </div>

  </section>
</template>

<script setup>
import { inject, useHead, ref } from '@/js/import/importHead.js';
import { ContactForm, ContactInfo } from '@/js/import/importView.js';

const $logo = inject('$logo');


useHead({
  title: 'Me contacter',
  meta: [
    { name: 'description', content: 'Me contacter' },
    { name: 'robots', content: 'index, follow' },
    { name: 'og:title', content: 'Me contacter' },
    { name: 'og:description', content: 'Me contacter' },
    { name: 'og:image', content: $logo },
    { name: 'og:url', content: 'https://nat-halite-doula.com/contact' },
    { name: 'og:type', content: 'website' },
    { name: 'og:locale', content: 'fr_FR' },
    { name: 'og:site_name', content: 'NatHalité Doula' },
    { name: 'og:image:width', content: '1200' },
    { name: 'og:image:height', content: '630' }
  ]
});
</script>

<style lang="scss" scoped>
@import '@/styles/contact.scss';

#contact {
  background-color: $bg-section-3;
  @include flex-column;
  justify-content: center;
  align-items: center;
  gap: rem(90);
  margin-top: rem(100);
  min-width: 100%;

  .row {
    gap: rem(16);
    justify-content: center;
    align-items: center;
  }

  .row > div {
    flex: 1;
  }

  header {
    @include flex-column;
    gap: rem(50);
    @include flex-center;
    text-align: center;
  }

  .contact-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: rem(64);
    padding: rem(10) rem(40);
    width: 100%;
    height: 100%;
    
    :deep(.contact-info) {
      flex: 1;
      max-width: 40%;
      align-self: center;
      height: fit-content;
    }

    :deep(.contact-form) {
      flex: 2;
      max-width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  article {
    padding: 1rem;
    margin-bottom: 2rem;
    flex: 0 0 50%;

    h3 {
      text-align: start;
      @include font-size-responsive(0.8rem, 1.2rem);
;
    }

    p {
      font-size: 1.2rem;
      line-height: 1.5;
      margin: 0;
    }
  }
}

</style>