<template>
    <div class="admin-left-nav-bar" :class="{ 'is-open': isOpen, 'is-closed': !isOpen }">
      <div class="toggle-button" @click="toggleNavBar">
        <span v-if="isOpen">❮</span>
        <span v-else>❯</span>
      </div>
      <div class="admin-left-nav-bar-content">
        <div class="admin-left-nav-bar-content-item">
          <router-link to="/backstage-control-center/dashboard">Administration</router-link>
          <router-link to="/backstage-control-center/edit-content">Articles</router-link>
          <router-link to="/backstage-control-center/edit-content">Pages</router-link>
          <router-link to="/backstage-control-center/settings">Paramètres</router-link>
          <router-link to="/backstage-control-center/users">Utilisateurs</router-link>
          <div class="btn-block">
            <Button 
              :colorClass="'bg-danger text-light'" 
            buttonClass="button" 
            :hoverColorClass="'hover-bg-danger-hover hover-text-light'" 
            buttonText="Se déconnecter"
            sizeClass="size-medium"
            paddingClass="padding-small"
            marginClass="margin-small"
            @click="handleLogout"
          />
          </div>
        </div>
      </div>
    </div>
  </template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useAuth } from '@/composables/useAuth';
import Button from '@/components/Button.vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const { logout, isAdmin, checkAdminStatus } = useAuth();
const isOpen = ref(true); // La barre de navigation est ouverte par défaut

const handleLogout = async () => {
  await logout();
  router.push('/login');
};

const toggleNavBar = () => {
  isOpen.value = !isOpen.value;
  localStorage.setItem('adminNavBarState', isOpen.value ? 'open' : 'closed');
};

onMounted(() => {
  const savedState = localStorage.getItem('adminNavBarState');
  if (savedState) {
    isOpen.value = savedState === 'open';
  }
  checkAdminStatus();
});

// Ajouter un watcher pour isAdmin
watch(isAdmin, (newValue) => {
  if (!newValue) {
    router.push('/login');
  }
});
</script>

<style lang="scss" scoped>
.admin-left-nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    height: 100%;
    background-color: #f5f5f5;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transform: translateX(0);
    padding-top: 11.375rem;
    z-index: 1000;
    transition: transform 0.3s ease;

    &.is-open {
        transform: translateX(0);
    }

    &.is-closed {
        transform: translateX(-100%);
    }

    .toggle-button {
        position: absolute;
        top: 50vh;
        right: -30px;
        background-color: #f5f5f5;
        border: 1px solid #ccc;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .admin-left-nav-bar-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;

        .admin-left-nav-bar-content-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}
</style>