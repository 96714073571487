<script setup>
import { ref } from 'vue';
import HeaderCommon from '@/components/HeaderCommon.vue';
import { photo_profil } from '@/js/import/images/home/homeImages.js';

const listItems = [
  { text: 'Accompagnante naissance', class: 'text-red' },
  { text: 'Sécurisante', class: 'text-purple' },
  { text: 'Bien-être', class: 'text-link' },
  { text: 'Écoute active', class: 'text-purple' },
  { text: 'Bienveillance', class: 'text-red' }
];
</script>

<template>
  <HeaderCommon
    title="NatHalité<br>Doula 02"
    :image="photo_profil"
    imageAlt="Photo de profil NatHalité Doula 02"
    :listItems="listItems"
    :isHtml="true"
  />
</template>