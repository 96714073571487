<script setup>
import NavBar from '@/views/NavBar.vue';
import Footer from '@/views/Footer.vue';
import AdminLeftNavBar from '@/components/adminLeftNavBar.vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useAuth } from '@/composables/useAuth';

const route = useRoute();
const isNotFoundPage = computed(() => route.name === 'NotFound');
const isThanxMessage = computed(() => route.name === 'ThanxMessage');
const { isAdmin } = useAuth();

const hideNavBar = computed(() => 
  isNotFoundPage.value || isThanxMessage.value
);
</script>

<template>
  <div class="page-container">
    <NavBar v-if="!hideNavBar"/>
    <div class="main-content">
      <AdminLeftNavBar v-if="isAdmin" />
      <div class="content">
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </div>
    <Footer v-if="!hideNavBar"/>
  </div>
</template>

<style lang="scss" scoped>
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  display: flex;
  flex: 1;
}

.content {
  flex: 1;
  width: 100vw;
  /* Supprimez le padding-left pour permettre à la barre de navigation de se superposer */
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
