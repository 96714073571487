// src/composables/useAuth.js
import { ref } from 'vue'

const user = ref(null)
const isAdmin = ref(false)

// Fonction pour récupérer l'utilisateur depuis le stockage local
const getUserFromLocalStorage = () => {
  const userData = localStorage.getItem('user');
  return userData ? JSON.parse(userData) : null;
};

// Logique pour déterminer si l'utilisateur est un administrateur
const checkAdminStatus = () => {
  const user = getUserFromLocalStorage();
  isAdmin.value = user && user.role === 'admin';
};

export function useAuth() {
  const login = async (username, password) => {
    if (username === 'admin' && password === 'password') {
      user.value = { username, role: 'admin' }; // Ajoutez le rôle ici
      localStorage.setItem('user', JSON.stringify(user.value));
      checkAdminStatus();
      return true;
    }
    return false;
  };

  const logout = async () => {
    user.value = null;
    localStorage.removeItem('user');
    isAdmin.value = false;
  };

  const isAuthenticated = () => {
    return !!user.value || !!localStorage.getItem('user');
  };

  const updateUserInfo = (newUsername, newPassword) => {
    console.log('updateUserInfo appelé avec:', newUsername, newPassword)
    const currentUser = getUserFromLocalStorage()
    if (currentUser) {
      currentUser.username = newUsername
      if (newPassword) {
        currentUser.password = newPassword // Note: Dans une vraie application, il faudrait hasher le mot de passe
      }
      localStorage.setItem('user', JSON.stringify(currentUser))
      user.value = currentUser
      return true
    }
    return false
  };

  return {
    user,
    login,
    logout,
    isAuthenticated,
    isAdmin,
    checkAdminStatus,
    updateUserInfo  // Assurez-vous que cette ligne est présente
  };
}

checkAdminStatus(); // Appel immédiat pour vérifier le statut admin