<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { formatDate } from '@/js/use/dateFormatter';
import ArticleCard from '@/components/articles/ArticleCard.vue';

const pages = ref([]);
const router = useRouter();

const fetchPages = async () => {
  try {
    const response = await axios.get('/api/pages');
    pages.value = response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des pages:', error);
  }
};

onMounted(fetchPages);

const navigateToPage = (pageUrl) => {
  router.push(`/articles/${pageUrl}`);
};
</script>

<template>
  <main id="articles">
    <section class="container">
      <header>
        <h1>Mes articles</h1>
      </header>
      <div class="article-list">
        <div v-if="pages.length === 0" class="no-articles">
          Aucun article disponible
        </div>
        <div v-else class="article-list__container">
          <ArticleCard 
            v-for="page in pages" 
            :key="page._id"
            :page="page"
            @navigate="navigateToPage"
          />
        </div>
      </div>
    </section>
  </main>
</template>

<style lang="scss" scoped>
@import '@/styles/articleList.scss';

#articles {
  background-color: $bg-section-2;
  min-height: 77vh;
}
</style>
