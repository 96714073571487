<script setup>
import { formatDate } from '@/js/use/dateFormatter';
import Button from '@/components/Button.vue'; 

const props = defineProps({
  page: {
    type: Object,
    required: true
  },
  isAdmin: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['navigate', 'edit', 'delete']);

const getExcerpt = (description, charCount) => {
  if (!description || typeof description !== 'string') {
    return 'Pas de description disponible';
  }
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = description;
  const textContent = tempDiv.textContent || tempDiv.innerText;
  const cleanContent = textContent.trim();
  if (cleanContent.length <= charCount) return cleanContent;
  return cleanContent.substring(0, charCount) + '...';
};

const getThumbnailImageUrl = (page) => {
  if (!page.thumbnailImage) return '';
  if (page.thumbnailImage.startsWith('data:image')) {
    return page.thumbnailImage;
  }
  return `${import.meta.env.VITE_API_URL}/api/pages/${page._id}/image/thumbnailImage`;
};
</script>

<template>
  <div class="article-list__item">
    <div 
      class="article-card" 
      :class="{ 'is-admin': isAdmin, 'is-clickable': !isAdmin }"
      @click="!isAdmin && $emit('navigate', page.url)"
    >
      <!-- Actions spécifiques à l'admin -->
      <div v-if="isAdmin" class="actions">
        <div class="action-buttons">
          <router-link :to="`/articles/${page.url}`" class="btn-link">Voir</router-link>
          <div class="btn-block">
            <Button 
              :colorClass="'bg-warning text-dark'" 
              buttonClass="button" 
              :hoverColorClass="'hover-bg-warning-hover hover-text-dark'" 
              buttonText="Modifier"
              sizeClass="size-medium"
              paddingClass="padding-small"
              marginClass="margin-small"
              @click="$emit('edit', page._id)"
            />
          </div>
          <div class="btn-block">
            <Button 
              :colorClass="'bg-danger text-light'" 
              buttonClass="button" 
              :hoverColorClass="'hover-bg-danger-hover hover-text-light'" 
              buttonText="Supprimer"
              sizeClass="size-medium"
              paddingClass="padding-small"
              marginClass="margin-small"
              @click="$emit('delete', page._id)"
            />
          </div>
        </div>
      </div>

      <div class="article-content">
        <div class="date" v-if="page.createdAt">
          {{ formatDate(page.createdAt) }}
        </div>
        <h2>{{ page.title }}</h2>
        <div v-if="page.thumbnailImage" class="thumbnail-container">
          <img 
            :src="page.thumbnailImage" 
            :alt="page.title" 
            class="thumbnail-image image-preview thumbnail-preview"
          />
        </div>
        <p>{{ getExcerpt(page.description, 150) }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/articleList.scss';

.article-card {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  position: relative;
  background: white;
  border-radius: rem(8);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
  &.is-clickable {
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    
    &:hover {
      transform: translateY(-rem(4));
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }
  }
  
  .article-content {
    display: flex;
    flex-direction: column;
    padding: rem(24);
    gap: rem(24);
    flex: 1 0 auto;

    .date {
      align-self: flex-end;
      background: rgba(255, 255, 255, 0.9);
      padding: rem(4) rem(8);
      border-radius: rem(4);
      @include font-size-responsive(rem(12), rem(14));
      margin-bottom: rem(8);
      position: absolute;
      top: rem(24);
      right: rem(24);
      z-index: 1;
    }

    h2 {
      margin: rem(32) 0 0;
      @include font-size-responsive(rem(24), rem(32));
      font-weight: 600;
      line-height: 1.3;
    }

    .thumbnail-image {
      width: 100%;
      height: rem(200);
      object-fit: cover;
      border-radius: rem(8);
      margin: rem(8) 0;
    }

    p {
      margin: 0;
      line-height: 1.6;
    }
  }
}

.is-admin {
  cursor: default;
  
  .actions {
    padding: rem(24);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    
    .action-buttons {
      display: flex;
      flex-direction: column;
      gap: rem(12);
    }
  }

  .btn-link {
    text-decoration: none;
    color: $link-color;
    text-align: center;
    padding: rem(8);
    
    &:hover {
      text-decoration: underline;
    }
  }

  .btn-block {
    display: flex;
    justify-content: center;
  }
}
</style> 