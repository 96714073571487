<script setup>

import { ref } from '@/js/import/importHead.js';
import { doula_mythologie, doula_mythologie_2 } from '@/js/import/images/about/aboutImage.js';

const title = ref('Histoire de la doula');
</script>   

<template>
  <!-- doula-story section -->
  <article id="doula-story" class="container box-shadow-1" itemprop="mainContentOfPage" itemscope itemtype="https://schema.org/WebPageElement">
    <header itemprop="headline">
      <h2 itemprop="about">{{ title }}</h2>
    </header>
    <div class="story-content" itemprop="articleBody">
      <div class="image-float-left">
        <figure class="image-figure">
            <img class="responsive-img" :src="doula_mythologie" alt="Illustration Alcmène, scène d'accouchement" itemprop="image" />
            <figcaption>
              <a href="https://wellcomecollection.org/works/jv3qepdf" target="_blank" class="a-link-none">© Wellcome Collection</a>
          </figcaption>
        </figure>
      </div>


      <div class="text-wrapper" itemprop="articleBody">
        <p><strong itemprop="about">Le mythe de <span itemprop="character">Galanthis</span></strong> est une histoire de la <strong>mythologie grecque</strong>, 
          liée à la naissance du héros <strong>Héraclès (Hercule)</strong>. <strong>Galanthis</strong>, également connue sous le nom de Historis, était une servante de la mère d'<strong>Héraclès</strong>, Alcmène. Selon la légende, Alcmène, enceinte de Zeus, était sur le point d'accoucher du futur héros, mais la déesse Héra, jalouse de l'infidélité de son mari Zeus, fit tout pour retarder la <strong>naissance</strong>. Elle envoya sa fille Ilithyie, déesse de <strong>l'accouchement</strong>, pour empêcher Alcmène de donner <strong>naissance</strong>.</p>
          <div class="image-float-right">
        <img class="responsive-img" :src="doula_mythologie_2" alt="Illustration de la naissance d'Heraclès" itemprop="image" />
      </div>
          <p><strong itemprop="character">Ilithyie</strong> s'assit à l'extérieur de la chambre d'<strong itemprop="character">Alcmène</strong>, les jambes et les doigts croisés, prononçant des incantations pour bloquer le travail. <strong itemprop="character">Galanthis</strong>, la servante fidèle, comprit ce qui se passait et, avec ruse, annonça que <strong>l'enfant</strong> était déjà né. Surprise par cette annonce, Ilithyie se leva et dénoua ses doigts et jambes, brisant ainsi l'incantation. Grâce à cette intervention, Alcmène put <strong>accoucher</strong> d'<strong>Héraclès</strong>.</p>
        <p>Cependant, la ruse de <strong itemprop="character">Galanthis</strong> ne resta pas impunie. <strong itemprop="character">Héra</strong>, furieuse de voir son plan échouer, transforma <strong itemprop="character">Galanthis</strong> en belette, animal considéré comme impur par les Grecs de l'Antiquité. Selon certaines versions du mythe, <strong itemprop="character">Galanthis</strong> fut cependant honorée par <strong itemprop="character">Alcmène</strong> et ses descendants pour son courage et sa dévotion. Le <strong itemprop="about">mythe de Galanthis</strong> est souvent interprété comme 
          un symbole de ruse et de bravoure, soulignant l'importance de l'ingéniosité face à l'adversité.</p>
        </div>
    </div>

    <footer class="footer-article">
      <cite class="a-link-none" itemprop="citation">
        <a href="https://doulas.info/galanthis-doula-de-mythologie/" target="_blank">Doulas de France</a>
      </cite>
    </footer>
  </article>
</template>

<style lang="scss" scoped>
#doula-story {
  background-color: $bg-section-1;
  padding: $padding-size;
  @include flex-column;

  .story-content {

    p {
      line-height: 250%;
      margin-bottom: rem(40);

      
      @include media-breakpoint-down(sm) {
        line-height: 150%;
        margin-bottom: rem(30);
      }

      @include media-breakpoint-down(md) {
        line-height: 220%;
        margin-bottom: rem(35);
      }

      @include media-breakpoint-down(lg) {
        line-height: 230%;
        margin-bottom: rem(35);
      }

      @include media-breakpoint-up(xl) {
        line-height: 250%;
      }
    }
  }

  .image-float-left {
    display: flex;
    flex-direction: column;
    @include flex-center;
    float: left;
    gap: rem(20);

    img {
      height: auto;
      margin: rem(10) 0;
      min-width: 150px;
      max-width: 250px;
      width: 100%;
      margin-right: rem(16);
    }

    .image-figure {
      margin: rem(16);
      text-align: center;

      @include media-breakpoint-down(sm) {
        margin: 0;
        text-align: center;
      }
    }
  }

  .image-float-right {
    display: flex;
    flex-direction: column;
    @include flex-center;
    float: right;
    gap: rem(20);
    margin-left: rem(16);

    img {
      height: auto;
      margin: rem(10) 0;
      min-width: 150px;
      max-width: 250px;
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      float: none;
      margin: 0 auto;
      text-align: center;
    }
  }

  .responsive-img {
    max-width: 100%;
    height: auto;
  }

  figcaption {
    font-size: rem(14);
    margin-top: rem(8);
  }

  .footer-article {
    @include flex-row;
    justify-content: end;
    margin-right: rem(34);
  }
}
</style>