<script setup>
import { ref } from 'vue';
import { Button, Modal } from '@/js/import/importComponents.js';
import { parcours, ecole, certif } from '@/js/import/images/home/homeImages.js';
import { useModal } from '@/js/use/useModal';

const { isModalOpen, modalImage, openModal, closeModal } = useModal();
</script>

<template>
  <!-- parcours section -->
  <section id="parcours" class="container box-shadow-1 container-section " itemscope itemtype="https://schema.org/WebPageElement">
    <div>
      <h2 class="text-center" itemprop="headline">Mon parcours</h2>
    </div>
    <section class="parcours-section container-text">
      <div><h3>Mes expériences</h3></div>
      <div class="parcours-content">
        <div class="ul-section">
          <ul itemprop="workExample">
            <li><span itemprop="date">2005</span><div class="arrow-block"><div class="arrow"></div></div><span itemprop="name">Aide à domicile</span></li>
            <li><span itemprop="date">2006 / 2009</span><div class="arrow-block"><div class="arrow"></div></div><span itemprop="name">Aide à domicile et Ash Hôpital de St-Quentin 02</span></li>
            <li><span itemprop="date">2010</span><div class="arrow-block"><div class="arrow"></div></div><span itemprop="name">École d'aide-soignante 6 mois</span></li>
            <li><span itemprop="date">2011</span><div class="arrow-block"><div class="arrow"></div></div><span itemprop="name">Accouchement fils</span></li>
            <li><span itemprop="date">2012</span><div class="arrow-block"><div class="arrow"></div></div><span itemprop="name">Fin d'école d'aide-soignante</span></li>
            <li><span itemprop="date">2013</span><div class="arrow-block"><div class="arrow"></div></div><span itemprop="name">Aide asoignante APEI mas de Saint-Quentin</span></li>
            <li><span itemprop="date">2014 / 2016</span><div class="arrow-block"><div class="arrow"></div></div><span itemprop="name">Aide soignante à la maison de Fannie EHPAD</span></li>
            <li><span itemprop="date">2017 / 2020</span><div class="arrow-block"><div class="arrow"></div></div><span itemprop="name">Assisante - familiale thérapeuthique pour Prémontré</span></li>
            <li><span itemprop="date">2022 / 2024</span><div class="arrow-block"><div class="arrow"></div></div><span itemprop="name">Axuilaire de la puériculure</span></li>
            <li><span itemprop="date">2023</span><div class="arrow-block"><div class="arrow"></div></div><span itemprop="name">Doula certifiée </span></li>
            <li><span itemprop="date">2024</span><div class="arrow-block"><div class="arrow"></div></div><span itemprop="name">Création d'entreprise</span></li>
          </ul>
        </div>
        <div class="block-img">
          <img class="responsive-img" :src="parcours" alt="Chronologie du parcours de Nathalie Vieville" itemprop="logo" />
        </div>
      </div>
    </section>

    <section class="formation-section container-text">
      <div><h3>Mon centre de formation</h3></div>
      <div class="row">
        <div class="logo">
          <img class="responsive-img img-logo" :src="ecole" alt="Logo de l'école de formation" itemprop="logo" />
        </div>
      </div>
      <div class="formation-bloc row space-between bg-bloc-text">
        <p class="formation-text flex-1-50">J'ai choisi l'école d'envol et matrescence car elle a une certification qualiopi. 
          Nous sommes formées par des <strong itemprop="name">doulas</strong> en activité professionnelle et par des sages-femmes. 
          Certaines apprenant(e)s obtiennent un financement France travail. La formation est en présentiel et 
          en distanciel. Les moments de partages sont riches et nous faisons des mises en situation professionnelle 
          pour apprendre au mieux la posture de la <strong itemprop="name">Doula</strong>.</p>
        <div class="logo">
          <img class="responsive-img big-img" :src="certif" alt="Logo de l'école de formation" @click="openModal(certif)" itemprop="image" />
        </div>
      </div>
    </section>
  </section>

  <!-- Modale pour afficher l'image en grand format -->
  <Modal :isOpen="isModalOpen" :imageSrc="modalImage" @close="closeModal" />
</template>

<style lang="scss" scoped>
/* parcours */
#parcours {
  background-color: $bg-section-2;



  .parcours-section {
    width: 100%;


  }

  .parcours-content {
    align-self: stretch;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    background-color: $bg-section-3-opacity;
    width: 100%; /* Garantit que le conteneur occupe toute la largeur */
    gap: rem(32); /* Espace entre les éléments */
    padding: rem(32);
    
    .ul-section {
      @include flex-1-50;

      ul {
        list-style-type: none;
        padding: 0;

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-wrap: nowrap;
          font-size: 16px;
          position: relative; /* Assurez-vous que les flèches sont positionnées correctement */
        }
      }
    }

    .block-img {
      margin: auto;

      img {
        width: 100%;
        max-width: none;
      }
    }
  }

  .formation-section{
    width: 100%;
    @include flex-column;
    justify-content: center;
    align-items: center;


    h3 {
      margin-bottom: rem(32);
      margin-top: rem(32);
    }



  }
  
}

.formation-bloc {
  @include flex-column;
  @include flex-center;
  padding: rem(68);
}



.formation-text{
  line-height: 3.5vw;
  font-size: 1.2rem;
}

.big-img {
  width: 100%;
  max-width: rem(500);
  cursor: pointer;
  transition: transform 0.8s ease;
}
.big-img:hover {
  transform: scale(1.1);
}

/* Adaptation de la flèche et de son conteneur */
.arrow-block {
  width: 80%;
  gap: 1rem;
  @include flex-row;
  justify-content: center;
  align-items: center;
  position: relative; /* Pour le positionnement relatif */
}

.arrow {
  width: 80%; /* Pourcentage pour s'adapter au conteneur parent */
  margin: 0 rem(16);
  height: 0;
  border-top: 2px dashed #fff;
  position: relative; /* Position relative pour l'enfant absolu */
  
    /*&::after {
    content: "";
    position: absolute;
    height: 0;
    top: -10px;
    right: -2px; 
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #fff; 
  }*/
}

  /* Media Queries pour différentes tailles d'écran */
  @media (max-width: 768px) {

#parcours {
  .parcours-content {
    margin: auto;

    .ul-section {
      @include flex-1-100;

      
      .intro-list {
        gap: 1rem;
      }

        li {

          span {
            font-size: 12px;
          }
        }

      }
      .block-img {

        margin: auto;

        img {
          width: 100%;
          max-width: none;
          
        }
      }
    }


  }
}

/* Media Queries pour les tailles d'écran inférieures à 480px */
@media (max-width: 480px) {
  #parcours {
    padding: 1rem; /* Réduire le padding pour les très petites tailles d'écran */
    
    .parcours-content {
      flex-direction: column; /* Passer en colonne pour les très petites tailles d'écran */
      gap: 1rem; /* Réduire l'espace entre les éléments */
      
      .ul-section {
        width: 100%; /* Assurez-vous que l'.ul-section occupe toute la largeur */
        
        ul {
          padding: 0 1rem; /* Ajouter du padding pour éviter que le texte ne touche les bords */
          
          li {
            align-items: flex-start; /* Aligner les éléments au début */
            flex-direction: column;
            
            .arrow-block {
              width: 100%; /* Assurez-vous que le conteneur de la flèche occupe toute la largeur */
              justify-content: flex-start; /* Aligner les flèches au début */
            }

            .arrow {
            width: 100%; /* Réduire encore plus la largeur des flèches */
            border-top: 1.5px dashed #fff; /* Réduire l'épaisseur de la bordure */
            padding-top: 0.2rem;
            padding-bottom: 0.2rem;
            
            &::after {
            top: 0; /* Ajuster la position verticale */
            right: 0; /* Ajuster la position horizontale */
            border-top: 0px solid transparent;
            border-bottom: 0px solid transparent;
            border-left: 0px solid #fff; /* Réduire encore plus la taille de la flèche */
            }
        }

            span {
                font-size: 10px; /* Réduire la taille de la police */
            }
          }
        }
      }



      .block-img {
        margin: auto;

        img {
          width: 100%;
          max-width: none;
        }
      }
    }
  }


}
</style>