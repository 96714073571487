import { createRouter, createWebHistory } from 'vue-router'
import { nextTick } from 'vue';
import Home from '@/views/Home.vue';
import NotFound from '@/views/404_error.vue';
import Contact from '@/views/Contact.vue';
import ThanxMessage from '@/views/contact-path/thanxMessage.vue';
import About from '@/views/About.vue';
import ConfidentialityPolicy from '@/views/ConfidentialityPolicy.vue';
import Services from '@/views/Services.vue';
import ArticleList from '@/views/ArticleList.vue';
import RituelRebozoService from '@/views/services-path/rituel_rebozo.vue';
import MamansoloService from '@/views/services-path/maman_solo.vue';
import GroupeDeParoleService from '@/views/services-path/groupe_parole.vue';
import DesireEnfantService from '@/views/services-path/desire_enfant.vue';
import GrossesseService from '@/views/services-path/grossesse.vue';
import DeuilPerinatalService from '@/views/services-path/deuil_perinatal.vue';
import TenteRougeService from '@/views/services-path/tente_rouge.vue';
import BainRituelService from '@/views/services-path/bain_rituel.vue';
import RefermetureDeBassinService from '@/views/services-path/refermeture_de_bassin.vue';
import SoinOuvertureService from '@/views/services-path/soin_ouverture.vue';
import Forfaits from '@/views/Forfaits.vue';
import { scrollToSection, smoothScrollTo } from '@/js/use/scrollUtils';
import { useAuth } from '@/composables/useAuth'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/thanx-message',
    name: 'ThanxMessage',
    component: ThanxMessage
  },
  {
    path: '/confidentiality-policy',
    name: 'ConfidentialityPolicy',
    component: ConfidentialityPolicy
  },
  {
    path: '/services',
    component: Services,
    children: [
      {
        path: 'maman-solo',
        name: 'Maman-solo',
        component: MamansoloService,
        meta: { title: 'Maman solo' }
      },
      {
        path: 'groupe-de-parole',
        name: 'GroupeDeParole',
        component: GroupeDeParoleService,
        meta: { title: 'Groupe de Parole' }
      },
      {
        path: 'desire-enfant',
        name: 'DesireEnfant',
        component: DesireEnfantService,
        meta: { title: 'Désir d\'Enfant' }
      },
      {
        path: 'grossesse',
        name: 'Grossesse',
        component: GrossesseService,
        meta: { title: 'Grossesse' }
      },
      {
        path: 'deuil-perinatal',
        name: 'DeuilPerinatal',
        component: DeuilPerinatalService,
        meta: { title: 'Deuil Périnatal' }
      },
      {
        path: 'tente-rouge',
        name: 'TenteRouge',
        component: TenteRougeService,
        meta: { title: 'Tente rouge' }
      },
      {
        path: 'bain-rituel',
        name: 'BainRituel',
        component: BainRituelService,
        meta: { title: 'Bain Rituel' }
      },
      {
        path: 'rituel-rebozo',
        name: 'RituelRebozo',
        component: RituelRebozoService,
        meta: { title: 'Rituel Rebozo' }
      },
      {
        path: 'refermeture-de-bassin',
        name: 'RefermetureDeBassin',
        component: RefermetureDeBassinService,
        meta: { title: 'Refermeture de bassin' }
      },
      {
        path: 'soin-ouverture',
        name: 'SoinOuverture',
        component: SoinOuvertureService,
        meta: { title: 'Soin d\'ouverture' }
      },
    ]
  },
  
  {
    path: '/forfaits',
    name: 'Forfaits',
    component: Forfaits,
    meta: { title: 'Mes forfaits' }
  },
  {
    path: '/articles',
    name: 'ArticleList',
    component: ArticleList,
    meta: { title: 'Articles' }
  },
  {
    path: '/articles/:pageUrl',
    name: 'ArticleDisplay',
    component: () => import('@/views/admin/DisplayPage.vue'),
    meta: { title: 'Article' }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: { title: 'Me contacter' }
  },
  {
    path: '/thanx-message/:name', // Modification du path pour inclure le paramètre name
    name: 'ThanxMessage',
    component: ThanxMessage,
    props: true, // Permet de passer les paramètres d'URL comme props
    meta: { 
      title: 'Message envoyé',
      noindex: true // Pour éviter l'indexation de cette page
    }
  },
  {
    path: '/pages/:pageUrl',
    name: 'DisplayPage',
    component: () => import('@/views/admin/DisplayPage.vue')
  },
  {
    path: '/backstage-control-center',
    name: 'Admin',
    component: () => import('@/views/Admin.vue'),
    meta: { 
      requiresAuth: true,
      title: 'Administration',
      noindex: true
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/admin/Dashboard.vue'),
        meta: { title: 'Tableau de bord' }
      },
      {
        path: 'edit-content',
        name: 'EditContent',
        component: () => import('@/views/admin/EditContent.vue'),
        meta: { title: 'Édition du contenu' }
      },
      {
        path: 'add-page',
        name: 'AddPage',
        component: () => import('@/views/admin/AddPage.vue'),
        meta: { title: 'Ajouter une page' }
      },
      {
        path: 'DisplayPage',
        name: 'DisplayPage',
        component: () => import('@/views/admin/DisplayPage.vue'),
        meta: { title: 'Affichage de la page' }
      },
      {
        path: 'edit-page/:id',
        name: 'EditPage',
        component: () => import('@/views/admin/EditPage.vue'),
        meta: { title: 'Modifier la page' }
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import('@/views/admin/settings.vue'),
        meta: { title: 'Paramètres' }
      },
    ]
  },
  {
    path: '/secure-access-portal',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: { 
      title: 'Connexion',
      noindex: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.query.scrollTo) {
      return new Promise((resolve) => {
        setTimeout(() => {
          scrollToSection(to.query.scrollTo, null, 2500); // Adjust the duration here (in milliseconds)
          resolve();
        }, 300); // Delay to wait for the transition to finish
      });
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
})

// Guard global pour mettre à jour le titre de l'onglet
router.beforeEach((to, from, next) => {
  const { isAuthenticated } = useAuth()
  const baseTitle = 'Nathalité Doula 02'
  const pageTitle = to.meta.title ? `${baseTitle} - ${to.meta.title}` : baseTitle
  document.title = pageTitle

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next({ name: 'NotFound' }); // Redirige vers la page 404
    } else {
      next()
    }
  } else {
    next()
  }
})

// Hook afterEach pour gérer le défilement après le changement de route
router.afterEach((to, from) => {
  if (to.query.scrollTo) {
    nextTick(() => {
      const section = document.getElementById(to.query.scrollTo);
      if (section) {
        smoothScrollTo(section, 2500);
      }
    });
  }
});

export default router;


