<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import facebook from '@/assets/logo_facebook.png';
import instagram from '@/assets/logo_instagram.png';
import { inject } from 'vue';
import { useRouter } from 'vue-router';
import { scrollToSection, scrollToTop } from '@/js/use/scrollUtils.js';

const $logo = inject('$logo');
const isMenuOpen = ref(false);
const isSubMenuOpen = ref(false); // Added for mobile submenu
const isDesktop = ref(window.innerWidth >= 769);
const isLinkHovered = ref(false);
const isContactLinkActive = ref(false); // Ajout de la propriété réactive pour le lien "Contact"
const isScrolled = ref(false); // Ajout de la propriété réactive pour le défilement
const router = useRouter();

function handleLinkMouseOver() {
  isLinkHovered.value = true;
}

function handleLinkMouseLeave() {
  isLinkHovered.value = false;
}

function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value;
  if (!isMenuOpen.value) {
    isSubMenuOpen.value = false; // Fermer le sous-menu lorsque le menu principal se ferme
  }
}

function toggleSubMenu() {
  if (!isDesktop.value) {
    isSubMenuOpen.value = !isSubMenuOpen.value;
  }
}

function openSubMenu() {
  if (isDesktop.value) {
    isSubMenuOpen.value = true;
  }
}

function closeSubMenu() {
  if (isDesktop.value && !isLinkHovered.value) {
    isSubMenuOpen.value = false;
  }
}

function closeMenu() {
  isMenuOpen.value = false;
  isSubMenuOpen.value = false;
}

function updateIsDesktop() {
  isDesktop.value = window.innerWidth >= 769;
}

function handleClickOutside(event) {
  const menu = document.querySelector('nav');
  if (menu && !menu.contains(event.target)) {
    closeMenu();
  }
}

function handleScroll() {
  isScrolled.value = window.scrollY > 50; // Ajustez la valeur selon vos besoins
}

onMounted(() => {
  window.addEventListener('resize', updateIsDesktop);
  document.addEventListener('click', handleClickOutside);
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateIsDesktop);
  document.removeEventListener('click', handleClickOutside);
  window.removeEventListener('scroll', handleScroll);
});
</script>

<template id="#navBar">
  <nav :class="['Header', { 'scrolled': isScrolled }]" itemscope itemtype="https://schema.org/WPHeader">
    <!-- Logo -->
    <div class="logo" itemprop="logo">
      <img class="img-logo responsive-img" :src="$logo" alt="logo" />
    </div>

    <!-- Navigation bar -->
    <div class="navMenu" :class="{ 'is-open': isMenuOpen }" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave" itemscope itemtype="https://schema.org/SiteNavigationElement">
      <ul>
        <li itemprop="name">
          <router-link to="/" active-class="active" @click.native="scrollToSection('/', router); closeMenu()" itemprop="url">Ma présentation</router-link>
        </li>
        <li itemprop="name">
          <router-link to="/about" active-class="active" @click.native="scrollToSection('/about', router); closeMenu()" itemprop="url">
            <span itemprop="name">Votre doula, son rôle</span>
          </router-link>
        </li>
        <li class="has-submenu" @mouseover="openSubMenu" @mouseleave="closeSubMenu" itemprop="name">
          <a class="link-prestation" href="#" @click.prevent="toggleSubMenu" itemprop="url">Vos prestations <svg width="15px" height="15px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="1.392"> <path d="M10.9997 3.67163L12.9997 3.67163L12.9997 16.4999L16.2423 13.2574L17.6565 14.6716L11.9996 20.3285L6.34277 14.6716L7.75699 13.2574L10.9997 16.5002L10.9997 3.67163Z" fill="#780462"></path> </g><g id="SVGRepo_iconCarrier"> <path d="M10.9997 3.67163L12.9997 3.67163L12.9997 16.4999L16.2423 13.2574L17.6565 14.6716L11.9996 20.3285L6.34277 14.6716L7.75699 13.2574L10.9997 16.5002L10.9997 3.67163Z" fill="#780462"></path> </g></svg></a>
          <transition name="menu">
            <ul v-if="isSubMenuOpen" class="sub-menu" @mouseover="handleLinkMouseOver" @mouseleave="handleLinkMouseLeave">
              <li itemprop="name"><router-link to="/services/desire-enfant" active-class="active" @click.native="scrollToSection('desire-enfant', router); closeMenu()" itemprop="url">Désir d'enfant</router-link></li>
              <li itemprop="name"><router-link to="/services/grossesse" active-class="active" @click.native="scrollToSection('grossesse', router); closeMenu()" itemprop="url">Grossesse</router-link></li>
              <li itemprop="name"><router-link to="/services/maman-solo" active-class="active" @click.native="scrollToSection('maman-solo', router); closeMenu()" itemprop="url">Maman solo</router-link></li>
              <li itemprop="name"><router-link to="/services/groupe-de-parole" active-class="active" @click.native="scrollToSection('groupe-de-parole', router); closeMenu()" itemprop="url">Groupe de Parole</router-link></li>
              <li itemprop="name"><router-link to="/services/tente-rouge" active-class="active" @click.native="scrollToSection('tente-rouge', router); closeMenu()" itemprop="url">Tente rouge</router-link></li>
              <li itemprop="name"><router-link to="/services/bain-rituel" active-class="active" @click.native="scrollToSection('bain-rituel', router); closeMenu()" itemprop="url">Bain Rituel</router-link></li>
              <li itemprop="name"><router-link to="/services/rituel-rebozo" active-class="active" @click.native="scrollToSection('rituel-rebozo', router); closeMenu()" itemprop="url">Rituel Rebozo</router-link></li>
              <li itemprop="name"><router-link to="/services/soin-ouverture" active-class="active" @click.native="scrollToSection('soin-ouverture', router); closeMenu()" itemprop="url">Soin d'ouverture</router-link></li>
              <li itemprop="name"><router-link to="/services/refermeture-de-bassin" active-class="active" @click.native="scrollToSection('refermeture-de-bassin', router); closeMenu()" itemprop="url">Refermeture de bassin</router-link></li>
              <li itemprop="name"><router-link to="/services/deuil-perinatal" active-class="active" @click.native="scrollToSection('deuil-perinatal', router); closeMenu()" itemprop="url">Deuil périnatal</router-link></li>
            </ul>
          </transition>
        </li>
        <li itemprop="name"><router-link to="/forfaits" active-class="active" @click.native="scrollToSection('forfaits', router); closeMenu()" itemprop="url">Vos forfaits</router-link></li>
        <li itemprop="name"><router-link to="/articles" active-class="active" @click.native="scrollToSection('articles', router); closeMenu()" itemprop="url">Vos articles</router-link></li>
        <li itemprop="name">
          <router-link to="/contact?scrollTo=contact" active-class="active" @click.native="scrollToSection('contact', router); closeMenu()" itemprop="url">Contactons-nous</router-link>
        </li>
        <!-- Social media icons for mobile -->
        <div class="reseau mobile-only">
          <a href="https://www.facebook.com/nathalite.doula" target="_blank" itemprop="sameAs"><img class="responsive-img" :src="facebook" alt="facebook" /></a>
          <a href="https://www.instagram.com/nathalite.doula/" target="_blank" itemprop="sameAs"><img class="responsive-img" :src="instagram" alt="instagram" /></a>
        </div>
      </ul>

      <div class="burger" @click="toggleMenu" :class="{ 'is-open': isMenuOpen }">
        <div class="line1"></div>
        <div class="line2"></div>
        <div class="line3"></div>
      </div>
    </div>

    <!-- Social media icons for desktop -->
    <div class="reseau desktop-only">
      <a href="https://www.facebook.com/nathalite.doula" target="_blank" itemprop="sameAs"><img class="responsive-img" :src="facebook" alt="facebook" /></a>
      <a href="https://www.instagram.com/nathalite.doula/" target="_blank" itemprop="sameAs"><img class="responsive-img" :src="instagram" alt="instagram" /></a>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
@import '@/styles/navBar.scss';
</style>