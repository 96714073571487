<template>
  <section class="discovery-container">
    <h2>Découvrez aussi !</h2>

    <!-- Section À propos -->
    <div class="about-section">
      <div class="image-float-left">
        <figure class="image-figure">
          <img 
            class="responsive-img" 
            :src="doula_mythologie" 
            alt="Illustration Alcmène, scène d'accouchement" 
          />
          <figcaption>
            <a href="https://wellcomecollection.org/works/jv3qepdf" target="_blank" class="a-link-none">
              © Wellcome Collection
            </a>
          </figcaption>
        </figure>
      </div>

      <p>
        Découvrez le rôle essentiel d'une doula et comment je peux vous accompagner dans votre parcours de maternité. 
        Un soutien bienveillant, des conseils personnalisés et une écoute attentive pour vivre pleinement cette expérience unique.
      </p>
      <div class="btn-block">
        <Button 
          buttonText="En savoir plus sur le rôle de votre doula"
          to="/about"
          buttonClass="button"
          colorClass="bg-purple text-white"
          :hoverColorClass="'hover-bg-nav hover-text-link'"
          sizeClass="size-medium"
          paddingClass="padding-medium"
          marginClass="margin-medium"
        />
      </div>
    </div>

    <!-- Section Articles -->
    <div class="articles-section">
      <h3>Articles récents</h3>
      <div class="carousel-container">
        <button class="carousel-btn next" @click="regenerateArticles" :disabled="isAnimating">
          <span class="arrow">&#8250;</span>
        </button>
        
        <div class="articles-container">
          <div class="articles-wrapper">
            <ArticleCard 
              v-for="article in displayedArticles" 
              :key="article._id"
              :page="article"
              @navigate="navigateToPage"
              class="article-item"
            />
          </div>
        </div>
      </div>
      
      <div class="btn-block">
        <Button 
          buttonText="Voir tous les articles"
          to="/articles"
          buttonClass="button"
          colorClass="bg-red text-white"
          :hoverColorClass="'hover-bg-nav hover-text-link'"
          sizeClass="size-medium"
          paddingClass="padding-medium"
          marginClass="margin-medium"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, computed, ref } from '@/js/import/importHead.js';
import axios from 'axios';
import Button from '@/components/Button.vue';
import ArticleCard from '@/components/articles/ArticleCard.vue';
import { useRouter } from 'vue-router';
import { doula_mythologie } from '@/js/import/images/about/aboutImage.js';
import gsap from 'gsap';

const router = useRouter();
const allArticles = ref([]);
const currentIndex = ref(0);
const isAnimating = ref(false);
const displayedArticles = ref([]);

const fetchAllArticles = async () => {
  try {
    const response = await axios.get('/api/pages');
    allArticles.value = response.data;
    updateDisplayedArticles();
  } catch (error) {
    console.error('Erreur lors de la récupération des articles:', error);
  }
};

const updateDisplayedArticles = () => {
  const shuffled = [...allArticles.value].sort(() => 0.5 - Math.random());
  displayedArticles.value = shuffled.slice(0, 3);
};

const regenerateArticles = async () => {
  if (isAnimating.value) return;
  isAnimating.value = true;

  // Faire disparaître tous les articles actuels
  const tl = gsap.timeline();
  
  tl.to('.article-item', {
    opacity: 0,
    scale: 0.8,
    duration: 0.3,
    stagger: 0.1,
    onComplete: () => {
      // Mettre à jour les articles
      updateDisplayedArticles();
      
      // Faire apparaître les nouveaux articles
      gsap.to('.article-item', {
        opacity: 1,
        scale: 1,
        duration: 0.3,
        stagger: 0.1,
        onComplete: () => {
          isAnimating.value = false;
        }
      });
    }
  });
};

const navigateToPage = (pageUrl) => {
  router.push(`/articles/${pageUrl}`);
};

onMounted(fetchAllArticles);
</script>

<style lang="scss" scoped>
.discovery-container {
  padding: rem(60) 10vw;
  background-color: $bg-section-2;

  h2 {
    text-align: center;
    margin-bottom: rem(48);
    @include font-size-responsive(rem(32), rem(48));
  }

  .about-section {
    max-width: rem(800);
    margin: 0 auto rem(64);
    text-align: center;
    position: relative;

    .image-float-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      float: left;
      margin-right: rem(32);
      
      .image-figure {
        margin: rem(16);
        text-align: center;

        .responsive-img {
          height: auto;
          min-width: rem(150);
          max-width: rem(250);
          width: 100%;
        }

        figcaption {
          font-size: rem(14);
          margin-top: rem(8);

          a {
            text-decoration: none;
            color: inherit;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        float: none;
        margin: 0 auto rem(32);
      }
    }

    p {
      margin-bottom: rem(32);
      @include font-size-responsive(rem(16), rem(18));
      line-height: 1.6;
      color: $purple;
      text-align: left;
    }
  }

  .articles-section {
    h3 {
      text-align: center;
      margin-bottom: rem(32);
      @include font-size-responsive(rem(24), rem(32));
    }

    .carousel-container {
      position: relative;
      display: flex;
      align-items: center;
      gap: rem(16);
      margin: 0 auto;
      max-width: rem(1200);

      .articles-container {
        width: 100%;
        overflow: hidden;
        padding: 0 rem(32);

        .articles-wrapper {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          gap: rem(32);
          justify-content: center;
          width: 100%;

          :deep(.article-item) {
            flex: 1;
            min-width: calc((100% - rem(64)) / 3);
            max-width: calc((100% - rem(64)) / 3);
            opacity: 1;
            transform: scale(1);
          }
        }
      }

      .carousel-btn {
        background: $purple;
        border: none;
        border-radius: 50%;
        width: rem(40);
        height: rem(40);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s ease;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        &:hover {
          background: darken($purple, 10%);
          transform: translateY(-50%) scale(1.1);
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        .arrow {
          color: white;
          font-size: rem(24);
          line-height: 1;
        }

        &.next {
          right: rem(-60);
          position: absolute;
        }
      }
    }

    .btn-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: dynamic-padding(1);
      width: 100%;
      margin-top: rem(32);

      :deep(.button) {
        min-width: rem(247);
        max-width: rem(400);
        width: auto;
        height: auto;
        white-space: normal;
        padding: rem(15) rem(30);
        line-height: 1.2;
        text-align: center;
        border-radius: 50px;
        @include font-size-responsive(0.7rem, 1rem);
      }
    }

    // Media queries
    @include media-breakpoint-down(md) {
      padding: rem(32) rem(16);

      .articles-container {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
</style>

