<script setup>
import { ref, onMounted } from 'vue';
import { coupleImg, familleImg, familleHomosexuelleImg, familleMonoparentaleImg, jumeauxImg, 
    groupeDeParoleImg, desireDEnfantImg, grossesseImg, presenceAccouchementImg, deuilPerinatalImg, 
    fleurDeBlachImg, bainRituelImg } from '@/js/import/images/infinitSlider/inifinitSlider.js';

// Indice de l'élément actuellement visible
const currentIndex = ref(0);

// Liste des éléments du carrousel
const items = [
  { title: "Couple", src: coupleImg, alt: "Couple"},
  { title: "Famille simple ou recomposée", src: familleImg, alt: "Famille" },
  { title: "Famille homosexuelle", src: familleHomosexuelleImg, alt: "Famille homosexuelle" },
  { title: "Famille monoparentale", src: familleMonoparentaleImg, alt: "Famille monoparentale"},
  { title: "Famille de jumeaux", src: jumeauxImg, alt: "Famille de jumeaux"},
  { title: "Groupe de parole", src: groupeDeParoleImg, alt: "Groupe de parole" },
  { title: "Désire d'enfant", src: desireDEnfantImg, alt: "Désire d'enfant" },
  { title: "Grossesse", src: grossesseImg, alt: "Grossesse" },
  { title: "Présence à l'accouchement", src: presenceAccouchementImg, alt: "Présence à l'accouchement" },
  { title: "Deuil périnatal", src: deuilPerinatalImg, alt: "Deuil périnatal" },
  { title: "Fleur de blach", src: fleurDeBlachImg, alt: "Fleur de blach" },
  { title: "Bain rituel", src: bainRituelImg, alt: "Bain rituel" },
];

onMounted(() => {
  currentIndex.value = items.length;

  const sliderWrapper = document.querySelector('.infinit-slider-wrapper');

  const adjustScrollSpeed = () => {
    const screenWidth = window.innerWidth;
    let duration = 30; // Durée par défaut en secondes

    if (screenWidth < 600) {
      duration = 15; // Plus rapide pour les petits écrans
    } else if (screenWidth < 1200) {
      duration = 20; // Vitesse intermédiaire pour les écrans moyens
    }

    sliderWrapper.style.animationDuration = `${duration}s`;
  };

  adjustScrollSpeed();
  window.addEventListener('resize', adjustScrollSpeed);

  sliderWrapper.addEventListener('mouseover', () => {
    sliderWrapper.style.animationPlayState = 'paused';
  });

  sliderWrapper.addEventListener('mouseout', () => {
    sliderWrapper.style.animationPlayState = 'running';
  });
});
</script>

<template>
  <div class="infinit-slider" itemscope itemtype="https://schema.org/ItemList">
    <div class="infinit-slider-wrapper" :style="{ transform: `translateX(-${currentIndex * 100 / items.length}%)` }">
      <div
        v-for="(item, index) in items.concat(items)" 
        :key="index"
        class="infinit-slider-item"
      >
        <div><span>{{ item.title }}</span></div>
        <img class="infinit-slider-item-image" :src="item.src" :alt="item.alt" itemprop="images">
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.infinit-slider {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  background-color: $bg-section-4;
  white-space: nowrap;
  scrollbar-width: thin;
  scrollbar-color: $purple $bg-section-1;
  margin-bottom: 0;

  &::-webkit-scrollbar {
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $purple;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: $bg-section-1;
    z-index: 1;
  }
}

.infinit-slider-wrapper {
  display: inline-flex;
  width: max-content;
  gap: dynamic-padding(2);
  padding: dynamic-padding(1);
  animation: scroll 30s linear infinite;
  animation-play-state: running;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.infinit-slider-item {
  display: inline-block; /* Utilisez inline-block pour permettre le défilement horizontal */
  box-sizing: border-box;
  padding: dynamic-padding(1);
  text-align: center;
  width: auto;
  min-width: 220px;
  @include flex-column;
  justify-content: center;
  align-items: center;

  &:hover {
    .infinit-slider-item-image {
      transform: scale(1.3);
      transition: all 0.5s ease-in-out;
      box-shadow: 0 0 3px 0 $purple;
      z-index: 0;
    }

    span {
      font-size: 1.5rem;
      color: $red-color;
      transition: all 0.5s ease-in-out;
      z-index: 1;
    }
  }
}

.infinit-slider-item img {
  width: auto;
  height: 100px;
  transition: all 0.5s ease-in-out;
  z-index: 0;
}

/* Styles pour la barre de défilement */
.infinit-slider::-webkit-scrollbar {
  height: 12px; /* Augmenter la hauteur de la barre de défilement */
}

.infinit-slider::-webkit-scrollbar-thumb {
  background-color: $purple; /* Couleur de la poignée de défilement */
  border-radius: 10px; /* Arrondir les coins de la poignée */
}

.infinit-slider::-webkit-scrollbar-track {
  background-color: $bg-section-1; /* Couleur de la piste de défilement */
  z-index: 1;
}

/* Styles spécifiques pour les mobiles */
@media (max-width: 600px) {
  .infinit-slider::-webkit-scrollbar {
    height: 16px; /* Augmenter encore plus la hauteur pour les mobiles */
  }

  .infinit-slider::-webkit-scrollbar-thumb {
    border-radius: 8px; /* Ajuster le rayon pour les mobiles */
  }
}
</style>