<script setup>
import { ref, useHead } from '@/js/import/importHead.js';
import { ServiceEnd, GroupButton } from '@/js/import/importComponents.js';

const title = ref('Refermeture de bassin')


useHead({
  title: title.value,
  meta: [
    { name: 'description', content: 'Le resserrage de bassin avec un rebozo est une technique traditionnelle mexicaine utilisée pour aider les femmes post-partum à retrouver leur équilibre physique et émotionnel. Cette pratique est également connue sous le nom de cerrada ou fermeture de bassin.' },
    { name: 'keywords', content: 'resserrage de bassin, rebozo, post-partum, fermeture de bassin, équilibre physique, émotionnel' },
  ],
});

</script>

<template>
  <article class="service-content" itemscope itemtype="https://schema.org/Article" itemprop="articleBody">
    <header class="container box-shadow-1 header" itemprop="headline">      
      <h1>{{ title }}</h1>
      <p class="service-content__text_bold">
        Le <strong>resserrage de bassin</strong> avec un <strong>rebozo</strong> est une <strong>technique 
        traditionnelle mexicaine</strong> utilisée pour aider les femmes <strong>post-partum</strong> à retrouver
        leur <strong>équilibre physique</strong> et <strong>émotionnel</strong>. 
        Cette pratique est également connue sous le nom de <strong>"cerrada"</strong> ou <strong>"fermeture de bassin"</strong>.
      </p>
    </header>

    <section class="container">

      <div class="logo">
          <img class="img-logo" src="@/assets/logo_nathalite_doula.png" alt="Logo de NatHalité Doula" />
      </div>

      <div class="service-content__block">
        <h2>Matériel nécessaire :</h2>
      </div>

      <div class="service-content__block">

        <ul class="list">
          <li>
            <strong>Un rebozo:</strong>(un châle ou une écharpe mexicaine)
          </li>
        </ul>
      </div>

      <div class="service-content__block">
        <h2>Étapes du resserrage de bassin avec un rebozo :</h2>
      </div>

      <div class="service-content__block" >
        <ol class="list">
            <li>
              <strong>Préparation :</strong> 
              <ul class="list">
                <li>La femme doit être dans un environnement calme et confortable.</li>
                <li>Il est préférable de porter des vêtements légers ou un maillot de 
                  bain pour faciliter les manipulations..</li>
              </ul>
            </li>
            <li><strong>Positionnement :</strong> Pendant le travail, 
              <ul class="list">
                <li>La femme peut s'allonger sur le dos ou sur le côté, selon ce qui est le plus confortable pour elle.</li>
                <li>L'accompagnateur se tient à côté d'elle avec le rebozo.</li>
              </ul>
            </li>  
            <li><strong>Resserrage :</strong>Une 
              <ul class="list">
                <li>Le rebozo est placé sous le bassin de la femme, en passant sous les hanches.</li>
                <li>L'accompagnateur saisit les extrémités du rebozo et commence à le resserrer doucement autour du bassin.</li>
                <li>Le mouvement de resserrage doit être ferme mais doux, en respectant le confort de la femme.</li>
                <li>Le resserrage peut être maintenu pendant quelques minutes, permettant à la femme de se détendre et de ressentir le soutien du rebozo.</li>
                <li>Parfois, plusieurs passages sont effectués, en repositionnant le rebozo légèrement à chaque fois pour bien cibler toutes les zones du bassin.</li>
              </ul>
            </li>
            <li><strong>Libération : </strong>Après 
              <ul class="list">
                <li>Après quelques minutes, le rebozo est doucement desserré et retiré.</li>
                <li>La femme peut alors rester allongée quelques instants pour profiter de l'effet du resserrage.</li>
              </ul>
            </li>
          </ol>
      </div>
      <div class="service-content__block">
        <h2>Bienfaits du resserrage de bassin :</h2>
        <ul class="list">
          <li><strong>Physiques</strong> 
            <ul class="list disc-list">
              <li>Aide à réaligner le bassin après l'accouchement.</li>
              <li>Réduit les douleurs lombaires et pelviennes.</li>
              <li>Favorise la fermeture des tissus du bassin.</li>
            </ul>
          </li>
          <li>Il peut également aider à réduire les symptômes de la douleur musculaire.</li>
          <li>Le rebozo peut être utilisé pour aider à réduire les symptômes de la douleur musculaire.</li>
          <li>Il peut également aider à réduire les symptômes de la douleur musculaire.</li>
          <li>Le rebozo peut être utilisé pour aider à réduire les symptômes de la douleur musculaire.</li>
        </ul>
      </div>


      <div class="service-content__block">
        <p>
          Le resserrage de bassin avec un rebozo peut être une expérience profondément apaisante et bénéfique 
          pour les nouvelles mères, en aidant à leur récupération physique et émotionnelle après l'accouchement
        </p>
      </div>
    </section>

    <div class="service-end">
      <div class="row justify-center">
        <GroupButton />

      </div>
    </div>
  </article>
</template>

<script>


export default {
name: 'Bain rituel',
}


</script>

<style lang="scss" scoped>

@import '../../styles/services_item.scss';

</style>

