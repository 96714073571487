<script setup>
import { ref, useHead } from '@/js/import/importHead.js';
import { ServiceEnd, GroupButton } from '@/js/import/importComponents.js';

const title = ref('Deuil périnatal')


useHead({
  title: title.value,

  meta: [
    { name: 'description', content: 'Deuil périnatal avec NatHalité Doula. Un soutien émotionnel et pratique pour votre parcours vers la conception.' },
    { name: 'keywords', content: 'deuil périnatal, NatHalité Doula, soutien émotionnel, dépression, perte d\'enfant, douleur' },
  ],
});

</script>

<template>
  <article class="service-content" itemscope itemtype="https://schema.org/Article" itemprop="mainContentOfPage">


    <header class="container box-shadow-1 header" itemprop="headline">   
      <h1 itemprop="name">{{ title }}</h1>
      <p class="service-content__text_bold">
        Le <strong>deuil périnatal</strong> désigne le processus de deuil qui suit la perte d'un enfant 
        pendant la <strong>grossesse</strong> ou peu après la <strong>naissance</strong>. 
        Cette expérience est souvent extrêmement <strong>douloureuse</strong> et peut être dévastatrice 
        pour les <strong>parents</strong> et leur entourage.
      </p>
    </header>
    
    <section class="container" itemprop="articleBody">
      <div class="logo">
          <img class="img-logo" src="@/assets/logo_nathalite_doula.png" alt="Logo de NatHalité Doula" itemprop="image"/>
        </div>

      <div class="service-content__block">
        <div> </div>
        <p>
          Une <strong>doula</strong> spécialisée dans l'accompagnement du <strong>deuil périnatal</strong> offre un <strong>soutien émotionnel et </strong>pratique 
          pendant cette <strong>période difficile</strong>. Voici quelques-unes des façons dont une doula peut aider :
        </p>   
      </div>
      <div class="service-content__block">
        <ol class="list">
          <li>
            <strong>Soutien émotionnel et physique : </strong> Être une présence réconfortante et compatissante pour les parents, 
            les écouter et leur offrir un espace pour exprimer leurs sentiments sans jugement.</li>
          <li>
            <strong>Soutien pratique</strong> : Aider les parents à naviguer les aspects pratiques liés à la perte, tels que les arrangements funéraires, 
            les souvenirs et les rituels de deuil.
          </li>
          <li>
            <strong>Informations et ressources :</strong> Fournir des informations sur le processus de deuil, les groupes de soutien, 
            les thérapeutes spécialisés et d'autres ressources disponibles.
          </li>
          <li>
            <strong>accompagnement à l'hôpital :</strong> Être présente pendant les moments critiques à l'hôpital, 
            comme l'accouchement ou les visites médicales post-perte, pour offrir un soutien continu.
          </li>
          <li><strong>Soutien continu :</strong> Maintenir un contact régulier avec les parents après la perte pour s'assurer qu'ils reçoivent le soutien dont ils ont besoin à long terme.</li>
        </ol>
      </div>

      <div class="service-content__block">
        <p>
          Le <strong>rôle de la doula</strong> dans le contexte du <strong>deuil périnatal</strong> est d'offrir une <strong>présence empathique</strong> 
          et de <strong>l'assistance</strong> dans un moment de <strong>vulnérabilité</strong> et de <strong>chagrin profond</strong>. 
          Elle aide à créer un <strong>espace sécurisé</strong> où les <strong>parents</strong> peuvent commencer à guérir et 
          trouver des moyens de <strong>commémorer</strong> leur <strong>enfant</strong>
        </p>
        <span>Tarif 80€ les deux heures</span>
      </div>
    </section>
    <div class="row justify-center">
        <GroupButton />
    </div>
  </article>
</template>

<style lang="scss" scoped>
@import '../../styles/services_item.scss';

</style>
