<script setup>
import { ref, useHead } from '@/js/import/importHead.js';
import { ServiceEnd, GroupButton } from '@/js/import/importComponents.js';

const title = ref('Grossesse')


useHead({
  title: title.value,

  meta: [
    { name: 'description', content: 'Découvrez l\'accompagnement par une doula pour les femmes et les familles avant, pendant et après l\'accouchement avec NatHalité Doula.' },
    { name: 'keywords', content: 'accompagnement doula, grossesse, accouchement, soutien familial' },
  ],
})


</script>

<template>
  <article class="service-content parallax" itemscope itemtype="https://schema.org/Article" itemprop="articleBody">

    <div class="background-scroll-container">
    </div>

    <header class="container box-shadow-1 header text-dark" itemprop="headline">
      <h1 itemprop="name">{{ title }}</h1>
      <p class="service-content__text_bold">
        <strong>L'accompagnement</strong> par une <strong>doula</strong> comprends diverses séances et services pour soutenir les <strong>femmes</strong> et les <strong>familles</strong> avant, pendant et après <strong>l'accouchement</strong>. Voici un aperçu des différentes séances et ce qu'elles peuvent inclure :
      </p>
    </header>
    <!-- Main page content-->

    <div class="container container-text">

      <!-- Page content with bg-->
      <div class="service-content__block-bg ">
      <section class="container" itemprop="section">

        <!-- Page content-->
        <div class="service-content__block">
          <h2>Avant l'accouchement</h2>
        </div>

        <div class="top-image">
          <img src="@/assets/service-img/grossesse-1.jpg" itemprop="image" alt="Image grossesse"/>

        </div>

        <div class="service-content__block" itemprop="articleSection">
          <ol class="list">
            <li><strong>Séance pré-natal</strong></li>
            <ul class="list">
              <li><strong>Préparation à la naissance :</strong> Discussions sur les attentes, les peurs, et les souhaits pour l'accouchement.</li>
              <li><strong>Éducation :</strong> Information sur le processus de l'accouchement, les techniques de gestion de la douleur, les interventions possibles et les choix disponibles.</li>
              <li><strong>Plan de naissance :</strong> Aide à la création d'un plan de naissance personnalisé.</li>
            </ul>
            <li><strong>Soutien émotionnel</strong></li>
            <ul class="list">
              <li><strong>Écoute active :</strong> Écoute des préoccupations et des questions de la future maman.</li>
              <li><strong>Techniques de relaxation :</strong> Apprentissage de techniques pour réduire le stress et l'anxiété.</li>
            </ul>
          </ol>
        </div>
      </section>

      <section class="container" itemprop="articleSection">
        <div class="service-content__block">
          <h2>Pendant l'accouchement</h2>
        </div>

        <div class="service-content__block">
          <ol class="list">
            <li><strong>Présence continue :</strong> La doula reste avec la mère tout au long du travail pour offrir un soutien constant.</li>
            <ul class="list">
              <li><strong>Soutien émotionnel et physique :</strong> Encouragement, massage, aide à la gestion de la douleur.</li>
              <li><strong>Coordination avec le personnel médical :</strong> Aide à la communication des souhaits de la mère au personnel médical.</li>
            </ul>
            <li><strong>Techniques de confort :</strong></li>
            <ul class="list">
              <li><strong>Positions d'accouchement :</strong> Aide à trouver des positions confortables pour soulager la douleur et favoriser la progression du travail.</li>
              <li><strong>Respiration et relaxation :</strong> Techniques de respiration pour gérer les contractions.</li>
            </ul>
          </ol>
        </div>

        <div class="service-content__block">
          <h3>Après l'accouchement</h3>
        </div>

        <div class="service-content__block">
          <ol class="list">
            <li><strong>Séances postnatales</strong></li>
            <ul class="list">
              <li><strong>Rétroaction sur l'accouchement :</strong> Discussion sur l'expérience de l'accouchement.</li>
              <li><strong>Soutien à l'allaitement :</strong> Aide avec les premières tétées, positionnement, et résolution des problèmes d'allaitement.</li>
            </ul>
            <li><strong>Soutien émotionnel :</strong></li>
            <ul class="list">
              <li><strong>Écoute et soutien :</strong> Aide avec le baby blues et les émotions post-partum.</li>
              <li><strong>Réseau de ressources :</strong> Références à d'autres professionnels si nécessaire (conseillers en lactation, thérapeutes, etc.).</li>
            </ul>
          </ol>
        </div>

        <div class="service-content__block">
          <h3>Séances spécifiques</h3>
        </div>

        <div class="service-content__block">
          <ul class="list">
            <li><strong>Séances de groupe :</strong> Ateliers ou groupes de soutien pour les futures mamans et les nouveaux parents.</li>
            <li><strong>Séances de rattrapage :</strong> Pour les parents qui n'ont pas pu assister à toutes les séances prénatales.</li>
          </ul>
        </div>

        <div class="service-content__block">
          <p>
            Chaque <strong>doula</strong> peut avoir une approche légèrement différente en fonction de sa 
            formation et de son expérience, mais en général, <strong>l'accompagnement</strong> vise à offrir un soutien 
            holistique pour améliorer l'expérience de la <strong>naissance</strong> et de la <strong>parentalité</strong>.
          </p>    
        </div>
      </section>

      <section class="container" itemprop="articleSection">
        <h3 class="service-content__block">Etudes associées à la présence d'une doula</h3>

        <p>Plusieurs études ont démontré que la présence d'une doula lors de 
          l'accouchement est associée à une diminution du recours à des interventions médicales, 
          y compris l'utilisation de péridurales et de césariennes. Voici un résumé des principales
          découvertes de ces études.
        </p>

        <h4>Réduction de l'Utilisation de Péridurales</h4>

        <ol class="list" start="1">
          <li><strong>Diminution du Recours aux Analgésiques :</strong> La présence d'une doula est liée 
            à une réduction significative de l'utilisation de la péridurale. Une méta-analyse de 26 études a montré que les femmes accompagnées 
            par une doula avaient environ 60% moins de chances de demander une péridurale par rapport à celles qui n'avaient pas de soutien continu.
          </li>
        </ol>

        <h4>Réduction de l'Utilisation de Césariennes</h4>

        <ol class="list" start="2">
          <li><strong>Réduction des Césariennes :</strong> La même méta-analyse a révélé que la présence d'une 
            doula peut réduire de 50% le risque de césarienne. Une étude publiée dans le Journal of Perinatal Education a également trouvé 
            que les femmes avec une doula avaient un taux de césarienne significativement plus bas (13,4% contre 25,0%).
          </li>
        </ol>

        <h4>Autres Avantages</h4>

        <ol class="list" start="3">
          <li><strong>Diminution des Autres Interventions Médicales :</strong> La présence d'une doula est également associée à une diminution
            du recours aux forceps, aux ventouses et à l'oxytocine pour accélérer le travail.
          </li>
          <li><strong>Réduction de la Durée du Travail :</strong> Les femmes accompagnées par une doula ont souvent des travails plus courts. 
            Une étude a trouvé que la durée moyenne du travail était réduite de 25% en présence d'une doula.
          </li>
          <li><strong>Amélioration de la Satisfaction :</strong> Les femmes soutenues par une doula rapportent une plus grande satisfaction 
            globale de leur expérience d'accouchement et une meilleure perception de leur capacité à accoucher naturellement.
          </li>
        </ol>
      </section>

      <section class="container" itemprop="articleSection">

        
      <div class="service-content__block">
        <h3>Exemples d'Études et de Résultats</h3>
        <ul class=" list disc-list">
          <li><strong>Étude de Klaus, Kennell et Klaus (1993) :</strong> Cette étude fondatrice a montré une réduction des taux de césarienne de 50% et une réduction de 60% de la durée du travail.</li>
          <li><strong>Étude de Hodnett et al. (2013) : </strong> Une revue Cochrane a confirmé que le soutien continu pendant le travail, fourni par des doulas, conduit à de meilleurs résultats pour les mères et les bébés, y compris moins de césariennes et d'analgésie.</li>
        </ul>
      </div>
      </section>

      <section class="container" itemprop="articleSection"> 
        <div class="service-content__block">
          <h3>Conclusion</h3>
          <p>La recherche soutient fortement l'idée que la présence d'une doula peut améliorer 
            les résultats de l'accouchement en réduisant le besoin d'interventions médicales 
            comme la péridurale et la césarienne, tout en augmentant la satisfaction des mères 
            concernant leur expérience de l'accouchement.
          </p>
        </div>
      </section>

      <section class="container" itemprop="articleSection"> 
        <div class="service-content__block">
          <h3>Sources</h3>

          <ol class="list reference-list">
            <li>Hodnett, E. D., Gates, S., Hofmeyr, G. J., Sakala, C., & Weston, J. (2013). Continuous support for women during childbirth. Cochrane Database of Systematic Reviews, (7).</li>
            <li>McGrath, S. K., & Kennell, J. H. (2008). A randomized controlled trial of continuous labor support for middle-class couples: Effect on cesarean delivery rates. Birth, 35(2), 92-97.</li>
            <li>Klaus, M. H., Kennell, J. H., & Klaus, P. H. (1993). Mothering the mother: How a doula can help you have a shorter, easier, and healthier birth. Addison-Wesley.</li>
          </ol>
        </div>
      </section>

      <div class="service-end">
      <div class="row justify-center">
        <GroupButton />
      </div>
    </div>

    </div>

    </div>




  </article>
</template>

<style lang="scss" scoped>
@import '../../styles/services_item.scss';


* :not(h1, h2, h3, h4, h5, h6){
  @include font-size-responsive(0.8rem, 1rem);
}

.service-content {
  background-color: #efd8cd;
}


.service-content__block-bg {
  max-width: max-content;
  @extend .text-dark;
}



</style>