<template>
  <HeaderCommon
    title="Le rôle de la doula"
    :image="doula_header"
    imageAlt="Illustration doula"
    :listItems="listItems"
    :imageCredit="{
      text: 'Designed by Freepik',
      url: 'http://www.freepik.com',
      platform: 'Freepik'
    }"
  />
</template>

<script setup>
import HeaderCommon from '@/components/HeaderCommon.vue';
import { doula_header } from '@/js/import/images/about/aboutImage.js';

const listItems = [
  { text: 'Accompagner', class: 'text-link' },
  { text: 'Informée', class: 'text-purple' },
  { text: 'Rassurer', class: 'text-red' },
  { text: 'Encourager', class: 'text-link' }
];
</script>
