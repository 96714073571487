<script setup>
import { onMounted, onUnmounted, useHead, ref } from '@/js/import/importHead.js';
import { useRouter, useRoute } from 'vue-router';
import { scrollToSection } from '@/js/use/scrollUtils';
import { inject } from 'vue';
import { ScrollToTopButton, Button } from '@/js/import/importComponents.js';
import { Header, Welcome, Parcours, Discovery } from '@/js/import/importView.js';
import { photo_profil, doula_accueil } from '@/js/import/images/home/homeImages.js';

const $logo = inject('$logo');
const router = useRouter();
const route = useRoute();

const title = ref('NatHalité Doula 02 - Doula accompagnatrice naissance dans l\'Aisne');
const description = ref('Nathalité Doula 02 propose un accompagnement personnalisé avant, pendant et après l\'accouchement. Doula professionnelle et à l\'écoute, Nathalie vous offre soutien émotionnel et conseils pour une grossesse sereine et un accouchement en douceur');

onMounted(() => {
  if (route.query.scrollTo) {
    scrollToSection(route.query.scrollTo, 3500);
  }
});

const jsonLd = {
  "@context": "https://schema.org",
  "@type": "Person",
  "name": "NatHalité Doula 02",
  "jobTitle": "Doula",
  "description": description.value,
  "image": photo_profil,
  "url": "https://nathalitedoula02.com",
  "sameAs": [
    "https://www.facebook.com/votrepagefacebook",
    "https://www.instagram.com/votrecompteinstagram"
  ],
  "address": {
    "@type": "PostalAddress",
    "addressLocality": "Saint-Quentin",
    "addressRegion": "Aisne",
    "addressCountry": "France"
  },
  "telephone": "+33123456789",
  "email": "contact@nathalitedoula02.com"
};

useHead({
  title: title.value,
  meta: [
    { name: 'description', content: description.value },
    { name: 'keywords', content: 'Doula, NatHalité Doula, Doula Saint-Quentin, Doula Laon, Doula Soissons, Doula Aisne, Accompagnatrice Naissance' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
    { property: 'og:title', content: title.value },
    { property: 'og:description', content: description.value },
    { property: 'og:image', content: photo_profil },
    { property: 'og:type', content: 'website' },
    { 'http-equiv': 'content-language', content: 'fr' },
  ],
  link: [
    { rel: 'canonical', href: 'https://nathalitedoula02.com' }
  ],
  script: [
    {
      type: 'application/ld+json',
      children: JSON.stringify(jsonLd)
    },
    { src: 'https://schema.org/WebPageElement', type: 'application/ld+json' },
    { src: 'https://schema.org/WPHeader', type: 'application/ld+json' },
    { src: 'https://schema.org/Quote', type: 'application/ld+json' },
    { src: 'https://schema.org/ItemList', type: 'application/ld+json' }
  ]
});

</script>



<template>
  <div class="home-page">

    <Header itemprop="header"></Header>


    <!-- Home section -->
    <main id="home" itemprop="mainContentOfPage" itemscope itemtype="https://schema.org/WebPageElement">

      <!-- welcome section -->
      <Welcome itemprop="mainEntity"></Welcome>

      <!-- parcours section -->
      <Parcours></Parcours>

      <!-- discovery section -->
      <Discovery></Discovery>
    </main>

    <!-- Scroll to Top button -->
    <div>
      <ScrollToTopButton/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
    ScrollToTopButton,
    Button
  },
};
</script>

<style lang="scss" scoped>
main {
  overflow: hidden;
  padding-top: rem(0);
}

.home-page {

  padding-top: rem(101); 
}
</style>